import React from "react";
import { HeaderTable, Actions, TableContainer } from "./styles";
import Button from "../button";
import { FiPlus, FiRefreshCw } from "react-icons/fi";

interface TableProps {
  title?: string;
  subTitles?: string[];
  buttonAddLabel?: string;
  buttonAddOnClick?: () => void;
  buttonRefreshLabel?: string;
  buttonRefreshOnClick?: () => void;
}

export function TdActions({ children }: React.PropsWithChildren<{}>) {
  return <Actions>{children}</Actions>;
}

export default function Table({
  title,
  children,
  buttonAddLabel,
  buttonAddOnClick,
  buttonRefreshLabel,
  buttonRefreshOnClick,
  subTitles,
}: React.PropsWithChildren<TableProps>) {
  return (
    <>
      <HeaderTable>
        <h1 className="title">{title}</h1>
        <HeaderTable>
          <Button isDense onClick={buttonRefreshOnClick}>
            <FiRefreshCw />
            {buttonRefreshLabel}
          </Button>
          <Button isDense onClick={buttonAddOnClick}>
            <FiPlus />
            {buttonAddLabel}
          </Button>
        </HeaderTable>
      </HeaderTable>
      <TableContainer>
        <thead>
          <tr>
            {subTitles?.map((subtitle, index) => (
              <th>{subtitle}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </TableContainer>
    </>
  );
}

Table.TdActions = TdActions;
