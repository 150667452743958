import styled from "styled-components";
export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableContainer = styled.table`
  border-collapse: collapse;
  margin-top: 16px;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  thead {
    background-color: var(--blackColor);
    tr {
      th {
        font-weight: 500;
        padding: 8px 12px;
        font-size: 1.4rem;
        text-align: start;
      }
    }
  }

  tbody {
    tr {
      transition: background-color 0.2s;

      td {
        padding: 16px 12px;
        font-size: 1.4rem;
        font-weight: 300;
      }
    }
    tr + tr {
      border-top: 2px solid var(--gray200);
    }
    tr:hover {
      cursor: pointer;
      background-color: var(--gray100);
    }
  }
`;

export const Actions = styled.td`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  svg,
  button {
    cursor: pointer;
    font-size: 1.8rem;
    &:hover {
      transition: 0.2s;
    }
  }
`;
