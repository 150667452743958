import styled from "styled-components";

interface ButtonProps {
  fullWidth?: boolean;
  isDense?: boolean;
}

export const ButtonContainer = styled.button<ButtonProps>`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  background-color: ${(props) =>
    props.color && props.color === "primary"
      ? "#0777D9"
      : props.color === "secondary"
      ? "#07D99D"
      : "#FF6161"};
  border: none;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.isDense ? "36px" : "48px")};
  display: flex;
  color: white;
  padding: ${(props) => (props.isDense ? "8px 16px" : "16px 32px")};
  text-align: center;
  text-decoration: none;
  font-size: ${(props) => (props.isDense ? "1.4rem" : "1.8rem")};
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${(props) =>
      props.color && props.color === "primary"
        ? "#309cf9"
        : props.color === "secondary"
        ? "#1ff7b9"
        : "#fb8d8d"};
  }
  svg {
    margin-right: 8px;
  }
`;
