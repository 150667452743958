import React from "react";
import { FiX } from "react-icons/fi";
import Button from "../button";
import { Container, WrapperContent, Header, Content, Footer } from "./styles";

type Props = {
  minWidth?: number;
  children?: React.ReactNode;
  title?: string;
  onClose?: () => void;
  onSave?: () => Promise<void> | void;
  rowsDirection?: "row" | "column";
};

export default function ModalForm({
  children,
  title,
  onClose,
  onSave,
  rowsDirection,
}: Props) {
  return (
    <Container
      onClick={(evt) => {
        if (evt.target === evt.currentTarget) {
          onClose?.();
        }
      }}
    >
      <WrapperContent>
        <Header>
          <h1 className="title">{title}</h1>
          <FiX size={24} onClick={onClose} />
        </Header>
        <Content rowsDirection={rowsDirection}>{children}</Content>
        <Footer>
          <Button isDense onClick={onSave}>
            Salvar
          </Button>
        </Footer>
      </WrapperContent>
    </Container>
  );
}
