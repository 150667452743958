import styled from "styled-components";
import Select from "react-select";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Label = styled.span`
  padding-bottom: 6px;
  font-size: 1.2rem;
`;

export const SelectInput = styled(Select)`
  span {
    background-color: transparent;
  }

  span + div {
    height: auto;
    width: 100%;
    border: none;
    background-color: var(--blackColor);
    padding: 6px 0;
    margin-right: 12px;
    font-size: 1.2rem;
    border-radius: 4px;
    box-shadow: none;
  }

  span + div :first-child :nth-child(2) {
    display: flex;
    align-items: center;
    &::after {
      content: "";
    }
  }

  span + div :first-child :first-child {
    min-width: 140px;
    display: flex;
    align-items: center;

    &::after {
      content: "";
    }
  }

  input + div {
    color: var(--whiteColor) !important;
  }
  span + div + div {
    margin-top: -2px;
    background-color: var(--blackColor);
    border-radius: 0 0 4px 4px;
    padding: 0;
    div {
      padding: 12px 4px;
      border-radius: 4px;
      margin-bottom: 2px;
      font-size: 1.2rem;
    }

    div :active {
      background-color: var(--blackColor) !important;
    }
    div + div :active {
      background-color: var(--blackColor) !important;
    }
  }
`;
