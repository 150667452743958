import {
  child,
  onDisconnect,
  onValue,
  ref,
  remove,
  set,
} from "@firebase/database";
import _ from "lodash";
import React from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { database } from "../../services/firebase";
import { Container, Description, Logo, WrapperContent } from "./styles";

export default function QRCodeBindPage() {
  const navigate = useNavigate();

  const monitorID = _.random(1, 99999999).toString();
  const cacheCityCouncilID = localStorage.getItem("cityCouncilID");

  React.useEffect(() => {
    if (cacheCityCouncilID) {
      navigate("/camara/" + cacheCityCouncilID);
      console.log("cacheCityCouncilID", cacheCityCouncilID);
    } else {
      const monitorsAvailable = ref(database, "monitorsAvailable");
      const monitorRef = child(monitorsAvailable, monitorID);
      set(monitorRef, "awaitingBind");
      onDisconnect(monitorRef).remove();
      onValue(monitorRef, async (snapshot) => {
        if (snapshot.exists() && snapshot.val() !== "awaitingBind") {
          const cityCouncilID = snapshot.val();
          await remove(snapshot.ref);
          navigate("/camara/" + cityCouncilID);
          localStorage.setItem("cityCouncilID", cityCouncilID);
        }
      });
    }
  }, [cacheCityCouncilID, monitorID, navigate]);

  return (
    <>
      <Container>
        <WrapperContent>
          <Logo src={logo} />
          <Description>
            Seu canal de gerenciamento das sessões legislativas de sua cidade.
          </Description>
          <div className="QRCodeContainer">
            <QRCode value={monitorID} size={200} />
          </div>
        </WrapperContent>
      </Container>
    </>
  );
}
