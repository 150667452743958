// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBq3TqIAH70nfENELNxixpfdlZ69HqSpjU",
  authDomain: "e-lsession.firebaseapp.com",
  databaseURL: "https://e-lsession-default-rtdb.firebaseio.com",
  projectId: "e-lsession",
  storageBucket: "e-lsession.appspot.com",
  messagingSenderId: "701048852061",
  appId: "1:701048852061:web:b4e400a95d0f5afc2907b4",
  measurementId: "G-8LCT66N6SL",
};

export const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
