import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  width: 100%;
  padding: 20px 0;
  height: 100vh;
  background-color: #0d0f1040;
  align-items: center;
  overflow-y: auto;
`;

export const WrapperContent = styled.div`
  background-color: var(--backgroundColor);
  padding: 12px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 4px;
  min-width: max(25vw,350px);
  max-width: (32vw, 700px);
  margin: auto 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 12px;
  h1 {
    margin-right: 8px;
  }
  svg {
    cursor: pointer;
  }
`;

interface IContent {
  rowsDirection?: "row" | "column";
}

export const Content = styled.div<IContent>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  ${({ rowsDirection }) =>
    rowsDirection === "column" &&
    css`
      flex-direction: column;
      flex-wrap: initial;
      > div {
        width: 100%;
      }
    `}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 60px;
  padding: 0 12px;
`;
