import React from "react";
import { Container, Label, SelectInput } from "./styles";

type props = {
  style?: React.CSSProperties;
  label?: string;
  placeholder?: string;
  options: Array<{ value: string; label: string }>;
  value?: string;
  onChange?: ((newValue: { value: string; label: string }) => void) | undefined;
};

export default function DropDown({
  style,
  label,
  placeholder,
  value,
  options,
  onChange,
}: props) {
  const [selected, setSelected] = React.useState<
    { value: string; label: string } | undefined
  >();

  React.useEffect(() => {
    if (value) {
      const defaultOption = options?.find((option) => option.value === value);
      setSelected(defaultOption);
    } else {
      setSelected(undefined);
    }
  }, [value, options]);

  return (
    <Container style={style}>
      <Label>{label}</Label>
      <SelectInput
        value={selected}
        placeholder={placeholder}
        onChange={(value) =>
          onChange?.(value as { value: string; label: string })
        }
        options={options}
        theme={(theme: { colors: any }) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#5EAAEC",
            neutral50: "#989898",
            neutral80: "#F2F2F2",
          },
        })}
      />
    </Container>
  );
}
