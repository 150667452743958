import React from "react";
import Button from "../../components/button";
import TextInput from "../../components/textInput";
import logo from "../../assets/images/logo.svg";
import { Container, Logo, Title, WrapperContent } from "./styles";
import { useNavigate } from "react-router-dom";
import { loginAsAdmin } from "../../services/adminService";

export default function LoginAdminPage() {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const tryLoginAdmin = async () => {
    setIsLoading(true);
    try {
      await loginAsAdmin(email, password);
      navigate("/admin");
    } catch (error: any) {
      if (error.code === "auth/user-not-found") {
        alert("Usuário não encontrado");
      } else if (error?.code === "auth/wrong-password") {
        alert("Senha incorreta");
      } else {
        alert(error);
      }
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <WrapperContent>
        <Logo src={logo} />
        <Title>ACESSO ADMINISTRATIVO</Title>
        <TextInput
          noMargin
          onChange={(evt) => setEmail(evt.target.value)}
          label="Email"
          placeholder="Digite seu email"
          type="email"
        />
        <TextInput
          style={{ marginTop: "1rem" }}
          noMargin
          onChange={(evt) => setPassword(evt.target.value)}
          label="Senha"
          placeholder="Digite sua Senha"
          type="password"
          onEnter={tryLoginAdmin}
        />
        <Button fullWidth onClick={tryLoginAdmin} loading={isLoading}>
          Fazer login
        </Button>
      </WrapperContent>
    </Container>
  );
}
