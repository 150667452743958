import styled, { keyframes } from 'styled-components'

const containerAnimation = keyframes`
    from {
        opacity: 0;
        scale: 0.4;
    }
    to {
        opacity: 1;
        scale: 1;
    }
`

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--backgroundColor);
  font-size: 4vw;
  animation: ${containerAnimation} 0.5s ease-in-out;
  .content {
    display: flex;
    align-items: center;

    width: 100vw;
    height: 100%;
    .vereador {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1vw;
      padding: 4vw;
      height: 100%;
      background-color: var(--blackColor);
      span {
        font-weight: 900;
        white-space: nowrap;
      }
      .logoApp {
        height: 4vw;
      }

      .info {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          background-color: var(--grayText);
          border-radius: 5%;
          aspect-ratio: 1/1;
          height: 19.4vw;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 70%;
            height: 70%;
            color: var(--blackColor);
          }
        }
      }
    }
    .timerContent {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1vw;
      width: 100%;
      .header {
        display: flex;
        justify-content: center;
        font-size: 3vw;
        width: 100%;
        font-weight: 600;
        border-bottom: 0.2vw solid var(--blackColor);
        padding: 1vw;
        color: var(--whiteColor);
      }
      .footer {
        display: flex;
        justify-content: space-between;

        font-size: 2vw;
        width: 100%;
        font-weight: 600;
        border-top: 0.2vw solid var(--blackColor);
        padding: 1vw;
        color: var(--whiteColor);
      }
    }
  }
`

interface TimerProps {
  color: string
}

export const Timer = styled.div<TimerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8.5vw;
  font-weight: 900;
  border-radius: 0.5vw;
  padding: 0.5vw;
  border: 0.2vw solid ${(props) => props.color};
  color: ${(props) => props.color};
  width: 90%;
`
