import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
`;

export const WrapperContent = styled.div`
  margin: auto;
  flex-direction: column;
  align-items: center;
  width: 300px;
  justify-content: center;
  display: flex;
  padding: 32px 0;

  .QRCodeContainer {
    background-color: #fff;
    padding: 10px;
  }
`;

export const Logo = styled.img`
  width: 70%;
  margin-bottom: 6px;
`;

export const Description = styled.span`
  margin-bottom: 16px;
  font-size: 14px;
  text-align: center;
  color: var(--whiteColor);
`;
