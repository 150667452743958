import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
`;

export const WrapperContent = styled.div`
  margin: auto;
  flex-direction: column;
  align-items: stretch;
  width: 300px;
  justify-content: center;
  display: flex;
  padding: 32px 0;

  button {
    margin-top: 16px;
  }
`;

export const Logo = styled.img`
  width: 70%;
  align-self: center;
  margin-bottom: 12px;
`;

export const Title = styled.h3`
  font-weight: 800;
  margin-bottom: 16px;
  align-self: center;
`;
