import { child, onValue, ref } from 'firebase/database';
import { collection, doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { CityCouncilModel } from '../models/cityCouncilModel';
import { LiveVotingModel } from '../models/liveVotingModel';
import { SessionModel } from '../models/sessionModel';
import { VotingModel } from '../models/votingModel';
import { database, firestore } from '../services/firebase';

export const useVoting = (cityCouncilID: string) => {
  const [cityCouncil, setCityCouncil] = useState<CityCouncilModel>();
  const [voting, setVoting] = useState<VotingModel>();
  const [liveMonitor, setLiveMonitor] = useState<VotingModel | "broadcasting_live_voting">();

  useEffect(() => {
    const cityCouncilRef = doc(firestore, 'cityCouncils', cityCouncilID);
    getDoc(cityCouncilRef)
      .then((cityCouncilData) => setCityCouncil({
        id: cityCouncilID,
        ...cityCouncilData.data()
      } as CityCouncilModel));
    const liveMonitor = child(ref(database, 'liveMonitor'), cityCouncilID);
    onValue(liveMonitor, async (snapshot) => {
      console.log('liveMonitor', snapshot.val());
      if (snapshot.exists()) {
        if (snapshot.val() === "broadcasting_live_voting") {
          setLiveMonitor("broadcasting_live_voting");
        } else {
          const liveVotingData = snapshot.val() as LiveVotingModel;
          setLiveMonitor(await loadVoting(cityCouncilID, liveVotingData));
        }
      } else {
        setLiveMonitor(undefined);
      }
    });

    const liveVoting = child(ref(database, 'liveVoting'), cityCouncilID);
    onValue(liveVoting, async (snapshot) => {
      console.log('liveVoting', snapshot.val());
      if (snapshot.exists()) {
        const liveVotingData = snapshot.val() as LiveVotingModel;
        if (voting) {
          setVoting({
            ...voting,
            status: liveVotingData.status,
            votes: liveVotingData.votes ?? {},
          })
        } else {
          setVoting(await loadVoting(cityCouncilID, liveVotingData));
        }
      } else {
        setVoting(undefined);
      }
    });
  }, [cityCouncilID]);

  const loadSession = async (cityCouncilID: string, sessionID: string): Promise<SessionModel> => {
    const cityCouncilRef = doc(firestore, 'cityCouncils', cityCouncilID);
    const sessionRef = doc(collection(cityCouncilRef, 'sessions'), sessionID);
    return {
      id: sessionID,
      ...(await getDoc(sessionRef)).data(),
    } as SessionModel;
  }

  const loadVoting = async (cityCouncilID: string, liveVotingData: LiveVotingModel): Promise<VotingModel> => {
    const cityCouncilRef = doc(firestore, 'cityCouncils', cityCouncilID);
    const sessionRef = doc(
      collection(cityCouncilRef, 'sessions'),
      liveVotingData.sessionID,
    );
    const votingRef = doc(collection(sessionRef, 'votings'), liveVotingData.id);
    return {
      id: liveVotingData.id,
      ...(await getDoc(votingRef)).data(),
      status: liveVotingData.status,
      votes: liveVotingData.votes ?? {},
      session: await loadSession(cityCouncilID, liveVotingData.sessionID),
    } as VotingModel;
  }

  return { cityCouncil, voting: liveMonitor == "broadcasting_live_voting" ? voting : liveMonitor };
};