import { child, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { TimerModel } from "../models/timerModel";
import { database } from "../services/firebase";

export const useTimer = (cityCouncilID: string) => {
  const [timerData, setTimer] = useState<TimerModel>();

  useEffect(() => {
    const liveTimer = child(ref(database, "liveTimer"), cityCouncilID);
    onValue(liveTimer, async (snapshot) => {
      console.log("snapshot", snapshot.val());
      if (snapshot.exists()) {
        const liveTimerData = snapshot.val() as TimerModel;
        setTimer(liveTimerData);
      } else {
        setTimer(undefined);
      }
    });
  }, [cityCouncilID]);

  return timerData;
};
