import React from "react";
import DropDown from "../../../components/dropDown";
import ModalForm from "../../../components/modalForm";
import TextInput from "../../../components/textInput";
import { CityCouncilModel } from "../../../models/cityCouncilModel";
import { ManagerModel } from "../../../models/managerModel";
import {
  ManagerToCreate,
  ManagerToUpdate,
} from "../../../services/adminService";

interface ModalFormProps {
  value?: ManagerModel | null;
  cityCouncils: CityCouncilModel[];
  onClose?: () => void;
  onSave?: (
    managerData: ManagerToCreate | ManagerToUpdate
  ) => Promise<void> | void;
}

export function EditManagerForm({
  value,
  onClose,
  onSave,
  cityCouncils,
}: ModalFormProps) {
  const [id, setID] = React.useState<string | undefined>();
  const [cityCouncilID, setCityCouncilID] = React.useState<
    string | undefined
  >();
  const [name, setName] = React.useState<string | undefined>();
  const [cpf, setCPF] = React.useState<string | undefined>();
  const [phone, setPhone] = React.useState<string | undefined>();
  const [password, setPassword] = React.useState<string | undefined>();

  const [councilOptions, setCouncilOptions] = React.useState<
    Array<{ value: string; label: string }>
  >([]);

  React.useEffect(() => {
    setID(value?.id);
    setCityCouncilID(value?.cityCouncilID);
    setName(value?.name);
    setCPF(value?.cpf);
    setPhone(value?.phone);
    setPassword(undefined);
  }, [value]);

  React.useEffect(() => {
    setCouncilOptions(
      cityCouncils.map((council) => ({
        value: council.id,
        label: council.name,
      }))
    );
  }, [cityCouncils]);

  const onSaveClick = async () => {
    if (!onSave) return;
    const data = { id, cityCouncilID, name, cpf, phone, password };
    if (!data.id) {
      if (!name || !cpf || !phone || !password)
        return alert("Preencha todos os campos");
    }
    console.log(data);
    await onSave(data as ManagerToCreate | ManagerToUpdate);
  };

  return (
    <ModalForm
      onClose={onClose}
      onSave={onSaveClick}
      title="Novo Administrador"
    >
      <DropDown
        style={{
          width: "100%",
        }}
        value={cityCouncilID}
        onChange={({ value }) => setCityCouncilID(value)}
        label="Câmara Legislativa *"
        placeholder="Selecione"
        options={councilOptions}
      />
      <TextInput
        value={name}
        onChange={(evt) => setName(evt.target.value)}
        placeholder="Digite seu nome"
        label="Nome *"
      />
      <TextInput
        value={cpf}
        onChange={(evt) => setCPF(evt.target.value)}
        placeholder="Digite seu CPF"
        label="CPF *"
        type="number"
      />
      <TextInput
        value={phone}
        onChange={(evt) => setPhone(evt.target.value)}
        placeholder="Digite seu telefone"
        label="Telefone"
        type="number"
      />
      <TextInput
        value={password}
        onChange={(evt) => setPassword(evt.target.value)}
        placeholder="Digite seu senha"
        label="Senha *"
        type="password"
      />
    </ModalForm>
  );
}
