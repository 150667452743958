import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginAdminPage from "./pages/loginAdmin";
import AdminHomePage from "./pages/adminHome";
import QRCodeBindPage from "./pages/QRCodeBind";
import VotingDetails from "./pages/votingDetails";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<QRCodeBindPage />} />
      <Route path="/login" element={<LoginAdminPage />} />
      <Route path="/admin" element={<AdminHomePage />} />
      <Route path="/camara/:id" element={<VotingDetails />} />
    </Routes>
  );
}
