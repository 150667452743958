import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

    :root {
        --backgroundColor: #1E1F23;
        --primaryColor: #0777D9;
        --secondaryColor: #10962D;
        --whiteColor: #F2F2F2;
        --blackColor: #0D0F10;
        --gray100: #303238;
        --gray200: #121516;
        --grayText: #989898;
        --redColor: #FF6161
    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    a:link {
        text-decoration: none;
    }

    html {
        font-size: 62.5%;
        @media (max-width: 800px) {
            font-size: 53%;
        }
    }


    body {
        min-height: 100vh;
        background-color: var(--backgroundColor);
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
    }

    body,
    input,
    button,
    text{
        font-family: 'Inter', sans-serif;
        color: var(--whiteColor);

    }


    ::-webkit-scrollbar-thumb {
        background-color: var(--gray200);
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
        background-color: #303238;
        border-radius: 10px;
    }

    ::-webkit-scrollbar {

        width: 10px;
        height: 20px;


    }


    .action-block {
        padding: 12px;
        position: fixed;
        background-color: var(--primaryColor);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        border-radius: 8px;
        max-width: 450px;
        max-height: 48px;
    }

    .action-name {
        font-size: 1.6rem;
        margin-left: 8px;
    }

    @keyframes slideIn {
    from {
        right: -450px;
    }
    to {
        right: 48px;
    }
    }

    @keyframes slideOut {
    from {
        right: 48px;
    }
    to {
        right: -450px;
    }
    }

    @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    }
`;
