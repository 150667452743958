import { auth, firestore, storage } from "./firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  User,
} from "firebase/auth";
import { ManagerModel } from "../models/managerModel";
import { CityCouncilModel } from "../models/cityCouncilModel";
import _, { pickBy } from "lodash";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import axios from "axios";

const usersCollection = collection(firestore, "users");
const cityCouncilsCollection = collection(firestore, "cityCouncils");
const host = "https://us-central1-e-lsession.cloudfunctions.net/api";
//const host = "http://127.0.0.1:5001/e-lsession/us-central1/api";


export const loginAsAdmin = async (email: string, password: string) => {
  let userData: User | undefined;
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    userData = user;
  } catch (error) {
    console.log(error);
    throw error;
  }
  if (!userData.email?.includes("@e-lsession.com")) {
    return userData;
  } else {
    throw "Usuário não é Admin";
  }
};

export const getAdminData = () => {
  return auth.currentUser;
};

export const getManagers = async (
  startNum: number = 0,
  limitNum: number = 20
) => {
  const q = query(
    usersCollection,
    orderBy("name"),
    where("role", "==", "manager"),
    startAt(startNum),
    limit(limitNum)
  );
  const managers = await getDocs(q);
  return managers.docs.map(
    (doc) =>
      ({
        id: doc.id,
        ...doc.data(),
      } as ManagerModel)
  );
};

export const getManagersByCityCouncil = async (cityCouncilID: string) => {
  const q = query(
    usersCollection,
    orderBy("name"),
    where("role", "==", "manager"),
    where("cityCouncil", "==", cityCouncilID)
  );
  const managers = await getDocs(q);
  return managers.docs.map(
    (doc) =>
      ({
        id: doc.id,
        ...doc.data(),
      } as ManagerModel)
  );
};

export const getCityCouncils = async (
  startNum: number = 0,
  limitNum: number = 20
) => {
  const q = query(
    cityCouncilsCollection,
    orderBy("name"),
    startAt(startNum),
    limit(limitNum)
  );

  const cityCouncils = await getDocs(q);
  return cityCouncils.docs.map(
    (doc) =>
      ({
        id: doc.id,
        ...doc.data(),
      } as CityCouncilModel)
  );
};

export interface ManagerToCreate {
  name: string;
  cpf: string;
  cityCouncilID: string;
  phone: string;
  password: string;
}


export const createManager = async (
  manager: ManagerToCreate,
) => {
  await axios.post(`${host}/manager/`, {
    name: manager.name,
    cpf: manager.cpf,
    cityCouncilID: manager.cityCouncilID,
    phone: manager.phone,
    password: manager.password,
  }, {headers:{
    Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
  }})
};

export interface ManagerToUpdate {
  id: string;
  name?: string;
  phone?: string;
  cityCouncilID?: string;
  password?: string;
}

export const updateManager = async (
  manager: ManagerToUpdate
) => {
   await axios.post(`${host}/manager/${manager.id}`, {
    name: manager.name,
    phone: manager.phone,
    cityCouncilID: manager.cityCouncilID,
    password: manager.password,
   },{headers:{
    Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
  },
});
};

export const deleteManager = async (managerID: string) => {
  await axios.delete(`${host}/user/${managerID}`, {headers:{
    Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
  }
});
}


export interface CityCouncilToCreate {
  name: string;
  state: string;
  city: string;
  photo: File;
}

export const createCityCouncil = async (cityCouncil: CityCouncilToCreate) => {
  console.log(File);

  const cityCouncilID = new Date().getTime().toString();
  const cityCouncilDoc = doc(cityCouncilsCollection, cityCouncilID);
  const photoRef = ref(storage, "cityCouncilsPhotos/" + cityCouncilID);
  await uploadBytes(photoRef, await cityCouncil.photo.arrayBuffer());
  await setDoc(cityCouncilDoc, {
    name: cityCouncil.name,
    city: cityCouncil.city,
    state: cityCouncil.state,
    photoURL: await getDownloadURL(photoRef),
  } as Omit<CityCouncilModel, "id">);
  return cityCouncilID;
};


export interface CityCouncilToUpdate {
  id: string;
  name?: string;
  state?: string;
  city?: string;
  photo?: File;
}

export const updateCityCouncil = async (
  cityCouncil: CityCouncilToUpdate
) => {
  const cityCouncilDoc = doc(cityCouncilsCollection, cityCouncil.id);
  const photoRef = ref(storage, "cityCouncilsPhotos/" + cityCouncil.id);
  let cityCouncilUpdated = {
    name: cityCouncil.name,
    city: cityCouncil.city,
    state: cityCouncil.state,
  } as Omit<CityCouncilModel, "id">;

  if (cityCouncil.photo) {
    await uploadBytes(photoRef, await cityCouncil.photo.arrayBuffer());
    cityCouncilUpdated.photoURL = await getDownloadURL(photoRef);
  }
  await setDoc(cityCouncilDoc, _.pickBy(cityCouncilUpdated), { merge: true });
}

export const deleteCityCouncil = async (cityCouncilID: string) => {
  await axios.delete(`https://us-central1-e-lsession.cloudfunctions.net/api/cityCouncil/${cityCouncilID}`,
  {headers:{
    Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
  }});
};
