import React from "react";
import Spinner from "../spinner";
import { ButtonContainer } from "./styles";

type props = {
  children: React.ReactNode;
  color?: "primary" | "secondary" | "tertiary";
  isDense?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fullWidth?: boolean;
  loading?: boolean;
};

export default function Button({
  children,
  onClick,
  fullWidth,
  isDense = false,
  color = "primary",
  loading = false,
}: props) {
  return (
    <ButtonContainer
      color={color}
      onClick={onClick}
      fullWidth={fullWidth}
      isDense={isDense}
      disabled={loading}
    >
      {loading ? <Spinner /> : children}
    </ButtonContainer>
  );
}
