import styled from "styled-components";

interface containerProps {
  noPadding?: boolean;
}

export const Container = styled.div<containerProps>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  flex: 1;
  ${({ noPadding }) => noPadding && "margin: 0;"};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Label = styled.span`
  padding-bottom: 6px;
  font-size: 1.2rem;
`;

export const Input = styled.input`
  border: none;
  position: relative;
  background-color: var(--blackColor);
  padding: 16px 12px;
  font-size: 1.2rem;
  border-radius: 5px;
  &[type="file"]::file-selector-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
