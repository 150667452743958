import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { collection, getDocs, query, where } from "firebase/firestore";
import { filter, uniqueId } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FiUser } from "react-icons/fi";
import { useParams } from "react-router-dom";
import logo from "../../assets/images/logo-horizontal.svg";
import TimerScreen from "../../components/timerScreen";
import { useTimer } from "../../hooks/useTimer";
import { useVoting } from "../../hooks/useVoting";
import { VoteValue, VotingStatus } from "../../models/liveVotingModel";
import { SessionType } from "../../models/sessionModel";
import { VoterModel } from "../../models/voterModel";
import { firestore } from "../../services/firebase";
import {
  CityCouncilor,
  Container,
  Status,
  Vote,
  VotingContent,
  VotingFooter,
  WaitContent,
  WaitFooter,
} from "./styles";

dayjs.locale("pt-br");

export default function VotingDetails() {
  const { id } = useParams();
  const [voters, setVoters] = useState([] as VoterModel[]);
  const [currentTime, setTime] = useState(new Date());
  const timerData = useTimer(id!);
  const { cityCouncil, voting } = useVoting(id!);

  useEffect(() => {
    const usersCollection = collection(firestore, "users");
    getDocs(
      query(
        usersCollection,
        where("role", "==", "cityCouncilor"),
        where("cityCouncilID", "==", id)
      )
    ).then((querySnapshot) => {
      if (querySnapshot.empty) return;
      setVoters(
        querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...(doc.data() ?? {}),
            } as VoterModel)
        )
      );
    });
  }, [id]);

  useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 1000);
  }, []);

  const currentVoters = useMemo(() => {
    if (!voting) return [];
    return filter(voters, (voter) => voter.id in voting.votes).map((voter) => ({
      ...voter,
      vote: voting.votes[voter.id],
    }));
  }, [voters, voting]);

  const sessionTypeString = {
    ordinary: "ORDINÁRIA",
    extraordinary: "EXTRAORDINÁRIA",
  } as Record<SessionType, string>;

  const voteValueString = {
    yes: "SIM",
    no: "NÃO",
    abstained: "ABSTENÇÃO",
    waitingVote: "NÃO VOTOU",
  } as Record<VoteValue, string>;

  const votingStatusString = {
    approved: "APROVADA",
    disapproved: "REJEITADO",
    notStarted: "NÃO INICIADA",
    onVote: "EM VOTAÇÃO",
  } as Record<VotingStatus, string>;

  const yesCount = filter(voting?.votes ?? [], (vote) => vote === "yes").length;
  const noCount = filter(voting?.votes ?? [], (vote) => vote === "no").length;
  const abstainCount = filter(
    voting?.votes ?? [],
    (vote) => vote === "abstained"
  ).length;

  let votingStatus: VotingStatus | undefined = voting?.status;

  if (voting) {
    const isApproved = yesCount >= voting!.votesNumberToApprove;

    const isDisapproved =
      noCount + abstainCount >
      Object.keys(voting!.votes).length - voting!.votesNumberToApprove;
    if (isApproved || isDisapproved) {
      votingStatus = isApproved ? "approved" : "disapproved";
    }
  }

  const [timerStartAt, cityCouncilorTalking] = useMemo(
    () => [
      currentTime,
      voters.find((voter) => voter.id === timerData?.cityCouncilorID),
    ],
    [timerData, voters]
  );

  return (
    <Container>
      {timerData ? (
        <TimerScreen
          timerStartTime={timerStartAt}
          duration={timerData.duration}
          cityCouncilorTalking={cityCouncilorTalking!}
          cityCouncil={cityCouncil}
        />
      ) : (
        <>
          {!voting ? (
            <>
              <WaitContent>
                {cityCouncil?.photoURL && (
                  <img
                    src={cityCouncil?.photoURL}
                    alt="Logo da câmara municipal"
                  />
                )}

                <h1>BEM-VINDO</h1>
                <h2>
                  {dayjs().format("DD [de] MMMM [de] YYYY").toUpperCase()}
                </h2>
              </WaitContent>
              <WaitFooter>
                Legisla Ativa - Todos os direitos reservados
              </WaitFooter>
            </>
          ) : (
            <>
              <VotingContent>
                <h2 className="header">
                  SESSÃO {sessionTypeString[voting!.session.type]}
                </h2>
                <div className="details">
                  <div className="header">
                    <h1 className="title">{voting!.title}</h1>
                    <h2 className="description">{voting!.description}</h2>
                  </div>
                  <div className="votes">
                    <Vote color="#07D99D">
                      SIM
                      <div className="value">{yesCount}</div>
                    </Vote>
                    <Vote color="#FF6161">
                      NÃO
                      <div className="value">{noCount}</div>
                    </Vote>
                    <Vote color="#989898">
                      ABSTEVE
                      <div className="value">{abstainCount}</div>
                    </Vote>
                  </div>
                </div>
                {voting.visibility === "private" ? (
                  <div className="privateContainer">Votação privada</div>
                ) : (
                  <div key={uniqueId()} className="votingCouncilors">
                    {currentVoters.map((voter, index) => (
                      <CityCouncilor key={index} vote={voter.vote}>
                        {voter.photoURL ? (
                          <img
                            src={voter.photoURL}
                            alt="Foto vereador"
                            className="avatar"
                          />
                        ) : (
                          <div className="avatar">
                            <FiUser />
                          </div>
                        )}

                        <div className="detailsCityCouncilor">
                          <span className="name">{voter.name}</span>
                          <span className="vote">
                            {voteValueString[voter.vote]}
                          </span>
                          <span className="party">{voter.politicalParty}</span>
                        </div>
                      </CityCouncilor>
                    ))}
                  </div>
                )}
              </VotingContent>
              <VotingFooter>
                <img alt="Logo da câmara municipal" src={logo} />
                <Status status={votingStatus!}>
                  {votingStatusString[votingStatus!]}
                </Status>
                <div>
                  <span className="date">
                    {dayjs(currentTime).format("DD/MM/YYYY")}
                  </span>
                  <span className="hour">
                    {dayjs(currentTime).format("HH:mm")}
                  </span>
                </div>
              </VotingFooter>
            </>
          )}
        </>
      )}
    </Container>
  );
}
