export class Action<T> {

  static runningActions: Action<any>[] = [];
  id: number;

  constructor(
    public name: string,
    public action: Promise<T>
  ) {
    this.id = Date.now();
  }

  public async run(): Promise<T> {
      Action.runningActions.push(this);
      const index = Action.runningActions.findIndex((a) => a.id === this.id);
      var status = 'pending';

      const action = new Promise<T>(
        (resolve, reject) => {
          this.action.then((res) => {
          resolve(res);
        }).catch((err) => reject(err));
      });

      const actionBlock = document.createElement("div");
      actionBlock.className = "action-block";
      actionBlock.style.animation = "slideIn 0.6s ease-out forwards";
      actionBlock.style.bottom = `${index * 64 + 32}px`;
      actionBlock.onanimationend = (type) => {
        if (type.animationName === "slideOut") {
          document.body.removeChild(actionBlock);
          Action.runningActions = Action.runningActions.filter((a) => a.id !== this.id);
        }else if (type.animationName === "slideIn") {
          setTimeout(() => {
            if (status !== 'pending') {
            actionBlock.style.animation = "slideOut 0.6s ease-in forwards";
            }else {
              action.finally(() => {
                actionBlock.style.animation = "slideOut 0.6s ease-in forwards";
              });
            }
          }, 500);
        }
      };
      const reloadIcon = document.createElement("svg");
      reloadIcon.innerHTML = `<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>`;
      reloadIcon.style.animation = "spin 1s linear infinite";
      const actionName = document.createElement("span");
      actionName.innerText = this.name;
      actionName.className = "action-name";
      actionBlock.appendChild(reloadIcon);
      actionBlock.appendChild(actionName);

      document.body.appendChild(actionBlock);

      return action.then((res) => {
        status = 'resolved';
        return res;
      }).catch((err) => {
        status = 'rejected';
        throw err;
      });
  }
}