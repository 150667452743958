import React from "react";
import { Container } from "./styles";

export default function Spinner() {
  return (
    <Container>
      <div className="loading-spinner"></div>
    </Container>
  );
}
