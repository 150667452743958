import styled from 'styled-components'
import { VotingStatus, VoteValue } from '../../models/liveVotingModel'

interface VoteValueProps {
  vote: VoteValue
}

interface VotingStatusProps {
  status: VotingStatus
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: space-between;
`

export const WaitContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  img {
    width: 50vw;
    margin-bottom: 5vw;
  }

  h1 {
    font-size: 7.6vw;
    font-weight: 900;
  }

  h2 {
    font-size: 3.6vw;
    font-weight: 400;
  }
`

export const WaitFooter = styled.div`
  padding: 18px 0;
  font-size: 1.6rem;
  width: 100%;
  background-color: var(--blackColor);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VotingContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1% 20px;
  h2 {
    font-size: 1.2vw;
    font-weight: 400;
  }
  .header {
    font-size: 1.4vw;
  }
  .details {
    width: 100%;
    padding: 1% 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .header {
      max-width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .title {
        font-size: 3.8vw;
        font-weight: 800;
      }
    }

    .votes {
      width: 30%;
    }
  }

  .votingCouncilors {
    margin-top: 0.2%;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .privateContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    font-weight: 900;
    color: var(--redColor);
  }
`

export const Vote = styled.div`
  color: ${(props) => props.color};
  font-size: 2.4vw;
  font-weight: 900;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1% 0;
  align-items: center;
  .value {
    background-color: ${(props) => props.color};
    width: 15%;
    color: var(--whiteColor);
    border-radius: 0.4vw;
    display: flex;
    justify-content: center;
  }
`

export const VotingFooter = styled.div`
  padding: 18px 20px;
  height: 12%;
  width: 100%;
  background-color: var(--blackColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 16vw;
  }
  .date {
    color: var(--primaryColor);
  }

  span {
    font-size: 3.6vw;
    font-weight: 800;
  }

  span + span {
    margin-left: 12px;
  }
`

export const Status = styled.h1<VotingStatusProps>`
  color: ${(props) =>
    props.status === 'approved' ? 'var(--secondaryColor)' : props.status === 'disapproved' ? 'var(--redColor)' : 'var(--primaryColor)'};
  font-size: 4vw;
  font-weight: 900;
`

export const CityCouncilor = styled.div<VoteValueProps>`
  margin: 0.2%;
  padding: 0.3%;
  min-width: 22%;

  width: auto;

  background-color: var(--blackColor);
  border: 0.2vw solid
    ${(props) => (props.vote === 'yes' ? 'var(--secondaryColor)' : props.vote === 'no' ? 'var(--redColor)' : 'var(--blackColor)')};
  border-radius: 0.5vw;
  display: flex;
  align-items: center;
  img {
    object-fit: cover;
    border-radius: 50%;
    aspect-ratio: 1/1;
    height: 4.4vw;
  }
  .detailsCityCouncilor {
    width: min-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1vw;
    .vote {
      color: ${(props) => (props.vote === 'yes' ? 'var(--secondaryColor)' : props.vote === 'no' ? 'var(--redColor)' : 'var(--grayText)')};
      font-weight: 600;
      font-size: 1.2vw;
    }
    .name {
      font-size: 2vw;
      font-weight: 900;
      white-space: nowrap;
    }
    .party {
      font-size: 0.8vw;
    }
  }
  .avatar {
    background-color: var(--grayText);
    border-radius: 50%;
    aspect-ratio: 1/1;
    height: 4.4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 70%;
      height: 70%;
      color: var(--blackColor);
    }
  }
`
