import React from "react";
import DropDown from "../../../components/dropDown";
import ModalForm from "../../../components/modalForm";
import TextInput from "../../../components/textInput";
import { CityCouncilModel } from "../../../models/cityCouncilModel";
import { FiX } from "react-icons/fi";
import _ from "lodash";
import axios from "axios";
import { PreviewImage } from "../styles";
import {
  CityCouncilToCreate,
  CityCouncilToUpdate,
} from "../../../services/adminService";
import { async } from "@firebase/util";

interface ModalFormProps {
  value?: CityCouncilModel | null;
  onClose?: () => void;
  onSave?: (
    cityCouncil: CityCouncilToCreate | CityCouncilToUpdate
  ) => Promise<void> | void;
}
export function CityCouncilForm({ value, onClose, onSave }: ModalFormProps) {
  const [name, setName] = React.useState<string | undefined>();
  const [state, setState] = React.useState<string | undefined>();
  const [city, setCity] = React.useState<string | undefined>();
  const [photo, setPhoto] = React.useState<File>();
  const [photoPreview, setPhotoPreview] = React.useState<string | undefined>();
  const fileImageRef = React.useRef<HTMLInputElement>(null);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  React.useEffect(() => {
    if (photo) {
      setPhotoPreview(URL.createObjectURL(photo));
    }
  }, [photo]);

  React.useEffect(() => {
    setName(value?.name);
    setState(value?.state);
    setCity(value?.city);
    if (value?.photoURL) {
      setPhotoPreview(value.photoURL);
    }
  }, [value]);

  function removeImage() {
    console.log(fileImageRef.current);
    fileImageRef.current!.value = "";
    setPhoto(undefined);
  }

  const onSaveClick = async () => {
    if (!onSave) return;
    const data = { name, state, city, photo, id: value?.id };
    if (!data.id) {
      if (!name || !state || !city || !photo)
        return alert("Preencha todos os campos");
    }
    await onSave(data as CityCouncilToCreate | CityCouncilToUpdate);
  };

  React.useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados", {
        params: {
          orderBy: "nome",
        },
      })
      .then((response) => {
        setStates(
          response.data.map((state: Record<string, string>) => ({
            label: state.nome,
            value: state.sigla,
          }))
        );
      });

    if (value?.state && value?.city) {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value?.state}/municipios`,
          {
            params: {
              orderBy: "nome",
            },
          }
        )
        .then((response) => {
          setCities(
            response.data.map((city: Record<string, string>) => ({
              label: city.nome,
              value: city.nome,
            }))
          );
        });
    }
  }, [value]);

  return (
    <>
      <ModalForm
        onClose={onClose}
        onSave={onSaveClick}
        title="Nova Câmara Municipal"
        rowsDirection="column"
      >
        {photoPreview && (
          <PreviewImage>
            {photo && (
              <FiX
                onClick={() => removeImage()}
                className="buttonClose"
                size={24}
              />
            )}
            <img src={photoPreview} alt="Logo Câmara" />
          </PreviewImage>
        )}
        <TextInput
          label="Logo Câmara"
          type="file"
          accept=".jpg, .png, .jpeg |image/*"
          inputRef={fileImageRef}
          onChange={async (evt) => {
            const file = evt.target.files?.[0];
            console.log(evt.target.files);
            if (file) {
              setPhoto(file);
            }
          }}
        />

        <TextInput
          value={name ?? ""}
          onChange={(evt) => setName(evt.target.value)}
          placeholder="Digite seu nome"
          label="Nome *"
        />

        <DropDown
          value={state}
          onChange={async ({ value }) => {
            setState(value);
            setCity(undefined);
            const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`;
            const { data } = await axios.get(url, {
              params: { orderBy: "nome" },
            });
            setCities(
              data.map((city: Record<string, string>) => ({
                label: city.nome,
                value: city.nome,
              }))
            );
          }}
          label="Estado"
          placeholder="Selecione"
          options={states}
        />
        <DropDown
          value={city}
          onChange={({ value }) => setCity(value)}
          label="Município"
          placeholder="Selecione"
          options={cities}
        />
      </ModalForm>
    </>
  );
}
