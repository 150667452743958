import dayjs from 'dayjs'
import React, { useState } from 'react'
import { VoterModel } from '../../models/voterModel'
import { Container, Timer } from './styles'
import logo from '../../assets/images/logo-horizontal.svg'
import { CityCouncilModel } from '../../models/cityCouncilModel'

interface TimerScreenProps {
  timerStartTime: Date
  duration: number
  cityCouncilorTalking: VoterModel
  cityCouncil?: CityCouncilModel
}

export default function TimerScreen({
  cityCouncilorTalking,
  timerStartTime,
  duration,
  cityCouncil,
}: React.PropsWithChildren<TimerScreenProps>) {
  const timerExpired =
    dayjs(timerStartTime)
      .add(duration ?? 0, 'minute')
      .diff(new Date(), 'minute', true) <= 0

  const [currentTime, setTime] = useState(new Date())
  const colorTimer = timerExpired ? '#C70000' : '#10962D'

  return (
    <Container>
      <div className="content">
        <div className="vereador">
          <img
            src={logo}
            alt="logo"
            className="logoApp"
          />
          <div className="info">
            <img
              src={cityCouncilorTalking.photoURL}
              alt={cityCouncilorTalking.name}
            />
            <span>{cityCouncilorTalking.name}</span>
          </div>
        </div>
        <div className="timerContent">
          <div className="header">Tempo Estimado: {dayjs(0).add(duration, 'minute').format('mm:ss')}</div>
          <Timer
            className="timer"
            color={colorTimer}
          >
            {dayjs(Date.now() - timerStartTime.getTime()).format('mm:ss')}
          </Timer>
          <div className="footer">
            <div className="camaraName">{cityCouncil?.name}</div>
            <div className="date">{dayjs(currentTime).format('DD/MM/YYYY')}</div>
          </div>
        </div>
      </div>
    </Container>
  )
}
