import React from "react";
import logoHorizontal from "../../assets/images/logo-horizontal.svg";
import { FiEdit, FiTrash } from "react-icons/fi";
import {
  Container,
  WrapperContent,
  Header,
  Logo,
  LogoutButton,
  UserInformation,
  Name,
  Office,
  TabContainer,
  Tab,
} from "./styles";
import { ManagerModel } from "../../models/managerModel";
import { CityCouncilModel } from "../../models/cityCouncilModel";
import {
  CityCouncilToCreate,
  CityCouncilToUpdate,
  createCityCouncil,
  createManager,
  deleteCityCouncil,
  deleteManager,
  getAdminData,
  getCityCouncils,
  getManagers,
  ManagerToCreate,
  ManagerToUpdate,
  updateCityCouncil,
  updateManager,
} from "../../services/adminService";
import { useNavigate } from "react-router-dom";
import { auth } from "../../services/firebase";
import { CityCouncilForm } from "./modals/cityCouncilModal";
import { EditManagerForm } from "./modals/managerForm";
import { Action } from "../../utils/actionView";
import _ from "lodash";
import Table from "../../components/Table";
import { doc } from "@firebase/firestore";

export default function AdminHomePage() {
  const [activeTab, setActiveTab] = React.useState("townHall");
  const [managerList, setManagerList] = React.useState([] as ManagerModel[]);
  const [cityCouncilList, setCityCouncilList] = React.useState(
    [] as CityCouncilModel[]
  );

  const [managerToEdit, setManagerToEdit] = React.useState<
    ManagerModel | undefined | null
  >();
  const [cityCouncilToEdit, setCityCouncilToEdit] = React.useState<
    CityCouncilModel | undefined | null
  >();

  const navigate = useNavigate();

  const loadCityCouncils = async () => {
    const action = new Action("Carregando Câmaras...", getCityCouncils(0, 20));
    const cityCouncils = await action.run();
    setCityCouncilList(cityCouncils);
  };

  const loadManagers = async () => {
    const action = new Action("Carregando Gerentes...", getManagers(0, 20));
    const managers = await action.run();
    setManagerList(managers);
  };

  const handleDeleteManager = async (
    evt: React.MouseEvent,
    manager: ManagerModel
  ) => {
    evt.stopPropagation();
    if (window.confirm(`Deseja realmente excluir ${manager.name}?`)) {
      const action = new Action(
        "Excluindo Gerente...",
        deleteManager(manager.id)
      );
      await action.run();
      setManagerList((list) => list.filter(({ id }) => id !== manager.id));
    }
  };

  const handleDeleteCityCouncil = async (
    evt: React.MouseEvent,
    cityCouncil: CityCouncilModel
  ) => {
    evt.stopPropagation();
    if (window.confirm(`Deseja realmente excluir ${cityCouncil.name}?`)) {
      const action = new Action(
        "Excluindo Câmara Legislativa...",
        deleteCityCouncil(cityCouncil.id)
      );
      await action.run();
      setCityCouncilList((list) =>
        list.filter(({ id }) => id !== cityCouncil.id)
      );
    }
  };

  const handleSaveCityCouncil = async (
    cityCouncil: CityCouncilToCreate | CityCouncilToUpdate
  ) => {
    let action: Action<string | void>;
    if ("id" in cityCouncil && cityCouncil.id !== undefined) {
      action = new Action(
        "Atualizando Câmara Legislativa...",
        updateCityCouncil(cityCouncil as CityCouncilToUpdate)
      );
    } else {
      action = new Action(
        "Criando Câmara Legislativa...",
        createCityCouncil(cityCouncil as CityCouncilToCreate)
      );
    }
    await action.run();
  };

  const handleSaveManager = async (
    manager: ManagerToCreate | ManagerToUpdate
  ) => {
    let action: Action<void>;
    if ("id" in manager && manager.id !== undefined) {
      action = new Action(
        "Atualizando Gerente...",
        updateManager(manager as ManagerToUpdate)
      );
    } else {
      action = new Action(
        "Criando Gerente...",
        createManager(manager as ManagerToCreate)
      );
    }
    await action.run();
  };

  React.useEffect(() => {
    loadCityCouncils();
    loadManagers();
  }, []);

  return (
    <>
      {managerToEdit !== undefined && (
        <EditManagerForm
          value={managerToEdit}
          cityCouncils={cityCouncilList}
          onSave={async (data) => {
            setManagerToEdit(undefined);
            await handleSaveManager(data);
            await loadManagers();
          }}
          onClose={() => setManagerToEdit(undefined)}
        />
      )}
      {cityCouncilToEdit !== undefined && (
        <CityCouncilForm
          value={cityCouncilToEdit}
          onSave={async (data) => {
            setCityCouncilToEdit(undefined);
            await handleSaveCityCouncil(data);
            await loadCityCouncils();
          }}
          onClose={() => setCityCouncilToEdit(undefined)}
        />
      )}
      <Container>
        <WrapperContent>
          <Header>
            <Logo src={logoHorizontal} />
            <LogoutButton
              onClick={async () => {
                await auth.signOut();
                navigate("/login");
              }}
            >
              Sair
            </LogoutButton>
          </Header>
          <UserInformation>
            <Name>{getAdminData()?.email}</Name>
            <Office>ADMINISTRAÇÃO</Office>
          </UserInformation>
          <TabContainer>
            <Tab
              active={activeTab === "townHall"}
              onClick={() => setActiveTab("townHall")}
            >
              Câmaras
            </Tab>
            <Tab
              active={activeTab === "managers"}
              onClick={() => setActiveTab("managers")}
            >
              Gerenciadores
            </Tab>
          </TabContainer>
          {activeTab === "townHall" ? (
            <Table
              title="Câmaras Legislativas"
              buttonAddLabel="Nova Câmara"
              buttonAddOnClick={() => setCityCouncilToEdit(null)}
              subTitles={["Nome", "Cidade", "Estado"]}
              buttonRefreshLabel="Atualizar"
              buttonRefreshOnClick={loadCityCouncils}
            >
              {cityCouncilList.map((cityCouncil, index) => (
                <tr onClick={() => setCityCouncilToEdit(cityCouncil)}>
                  <td>{cityCouncil.name}</td>
                  <td>{cityCouncil.city}</td>
                  <td>{cityCouncil.state}</td>
                  <Table.TdActions>
                    <FiEdit onClick={() => setCityCouncilToEdit(cityCouncil)} />
                    <FiTrash
                      viewBox="0 0 24 24"
                      style={{
                        color: window
                          .getComputedStyle(document.body)
                          .getPropertyValue("--redColor"),
                      }}
                      onClick={(evt) =>
                        handleDeleteCityCouncil(evt, cityCouncil)
                      }
                    />
                  </Table.TdActions>
                </tr>
              ))}
            </Table>
          ) : (
            <Table
              title="Gerenciadores"
              buttonAddLabel="Novo Gerenciador"
              buttonAddOnClick={() => setManagerToEdit(null)}
              buttonRefreshLabel="Atualizar"
              buttonRefreshOnClick={loadManagers}
              subTitles={["Nome", "CPF", "Câmara"]}
            >
              {managerList.map((admin) => {
                const cityCouncil = cityCouncilList.find(
                  (cityCouncil) => cityCouncil.id === admin.cityCouncilID
                );
                return (
                  <tr onClick={() => setManagerToEdit(admin)}>
                    <td>{admin.name}</td>
                    <td>{admin.cpf}</td>
                    <td>{cityCouncil?.name ?? "Não definido"}</td>
                    <Table.TdActions>
                      <FiEdit />
                      <FiTrash
                        style={{
                          color: window
                            .getComputedStyle(document.body)
                            .getPropertyValue("--redColor"),
                        }}
                        onClick={(evt) => handleDeleteManager(evt, admin)}
                      />
                    </Table.TdActions>
                  </tr>
                );
              })}
            </Table>
          )}
        </WrapperContent>
      </Container>
    </>
  );
}
