import React from "react";
import { Container, Label, Input } from "./styles";

interface props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  noMargin?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  style?: React.CSSProperties;
  accept?: string;
  onEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function TextInput({
  style,
  label,
  placeholder,
  disabled,
  noMargin = false,
  onEnter,
  accept,
  ...props
}: props) {
  return (
    <Container noPadding={noMargin} style={style}>
      <Label>{label}</Label>
      <Input
        {...props}
        ref={props.inputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter" && onEnter) onEnter(e);
        }}
        disabled={disabled}
        placeholder={placeholder}
        accept={accept}
      />
    </Container>
  );
}
