import styled, { css } from "styled-components";

interface tabProps {
  active?: boolean;
}

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
`;

export const WrapperContent = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
`;

export const Header = styled.div`
  padding: 22px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Logo = styled.img`
  height: 40px;
`;

export const LogoutButton = styled.button`
  background: none;
  border: none;
  color: var(--redColor);
  cursor: pointer;
`;

export const UserInformation = styled.div`
  margin-bottom: 24px;
`;

export const Name = styled.h1`
  font-size: 2.4em;
`;

export const Office = styled.span`
  font-size: 1.4em;
  color: var(--grayText);
`;

export const TabContainer = styled.div`
  width: 100%;
  margin: 16px 0;
  display: flex;
  align-items: flex-start;
  height: 28px;
  border-bottom: 2px solid #121516;
`;

export const Tab = styled.div<tabProps>`
  font-size: 1.4em;
  margin-right: 10px;
  font-weight: 500;
  padding: 0 6px;
  cursor: pointer;
  color: var(--grayText);
  height: 100%;
  ${(props) =>
    props.active &&
    css`
      color: var(--primaryColor);
      border-bottom: 2px solid var(--primaryColor);
    `};
`;

export const AddAdminsSession = styled.div`
  width: 100%;
  padding: 16px 0;
  h2 {
    margin-left: 12px;
  }
  .add {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-right: 12px;
  }
`;

export const PreviewImage = styled.div`
  padding: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .buttonClose {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: var(--redColor);
    font-size: 1.4em;
    padding: 0 8px;
  }
  img {
    width: min-content;
    height: 120px;
    object-fit: contain;
  }
`;
